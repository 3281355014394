import { AggregatedTaskStatusLabel, IRequest, RequestStatusLabel } from "../../types/requests";
import { formatDate } from "../../../../utils/date-format";
import { useSelectorTyped } from "../../../../common/redux/store";
import { buildAssociatedEntityLink, buildTeamDetailsPageLink } from "../../../../utils/navigation";

export type RequestDetailsHeaderProps = {
  requestDetails: IRequest;
};

export default function useRequestDetailsHeaderController({ requestDetails }: RequestDetailsHeaderProps) {
  const aggregatedTaskStatus = requestDetails.aggregatedTaskStatus;
  const { labelMaps } = useSelectorTyped((state) => state.header.uiConfigsPEV.value);
  const { requestTypeLabelMap } = labelMaps;

  return {
    requestDetails,
    getRequestTypeLabel: () => requestTypeLabelMap[requestDetails.type],
    getRequestStatusLabel: () => RequestStatusLabel[requestDetails.status].toUpperCase(),
    getClassNameForRequestStatus: () => `request-status-${requestDetails.status}`,
    getClassNameForAggregatedStatus: () => aggregatedTaskStatus
      && `aggregated-status-${aggregatedTaskStatus}`
      || "",
    getAggregatedTaskStatusLabel: () => aggregatedTaskStatus
      ? AggregatedTaskStatusLabel[aggregatedTaskStatus]
      : null,
    getRequestRaisedDateTime: () => formatDate(requestDetails.raisedDateTime),
    getAssociatedEntityDetails: () => ({
      link: buildAssociatedEntityLink(requestDetails.associatedEntity),
      name: requestDetails.associatedEntity.name,
      type: requestDetails.associatedEntity.type.toLowerCase(),
    }),
    getRequesterTeamDetails: () => {
      const { teamId, teamName } = requestDetails.requester;
      return {
        link: buildTeamDetailsPageLink({
          id: teamId,
          name: teamName,
        }),
        name: teamName,
      };
    },
    getTaskOwnerTeamDetails: () => {
      if (requestDetails.tasks.length === 0) {
        return null;
      }
      const { teamId, teamName } = requestDetails.tasks[0].taskOwner;
      return {
        link: buildTeamDetailsPageLink({
          id: teamId,
          name: teamName,
        }),
        name: teamName,
      };
    },
  };
}
